/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import Headroom from 'headroom.js';

$(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('.toggler').click(function () {
			//body.toggleClass('open');
			responsiveNav.toggleClass('-collapsed');
			toggler.toggleClass('-closed');
		});
	});

	// Navigation affix
	$('.responsive-nav').each(function () {
		var element = $(this),
			wrapper = element.parent(),
			elementOffset = $('header');

		var headroom = new Headroom(this, {
			"offset": elementOffset.height()
		});
		headroom.init();
		
		$(window).resize(function () {
			headroom.offset = elementOffset.height();
		});
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-height').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.match-height')[0]) {
		matchHeights();
	}

	// product information
	//var productWrappers = $('.product__wrapper');
	//productWrappers.each(function () {
	//	var element = $(this);

	//	element.on('touch', function () {
	//		productWrappers.removeClass('-open');
	//		element.toggleClass('-open');
	//	});
	//});

	// Products content
	$('.product__wrapper').each(function () {
		var element = $(this),
			content = element.find('.content')

		$(window).on("load resize", function (e) {
			// Set top position based on element
			var pos = element.position();
			content.css("top", pos.top + element.height());
		});

		element.click(function (event) {
			if (event.which === 1) {
				// Remove the active class from all elements with active class
				if (element.hasClass('active')) {
					element.removeClass('active');
					element.parent().removeClass('active');
				}
				else {
					$(".product__wrapper").removeClass("active");
					element.addClass("active");
					element.parent().addClass("active");

					$('html, body').animate({
						scrollTop: element.offset().top
					}, 500);
				}
				event.stopPropagation();
			}
		});

		//var close = $('<div class="close"><span>Sluiten</span></div>').click(function () {
		//    element.removeClass('active');
		//}).appendTo(content);
	});

	// Slideshow
	$('.slideshow').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: false,
			dots: true,
			fade: true,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		//await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Route form
	$('.route-form').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = element.find('#To');

		// Route on button click
		button.click(function (e) {
			if (destination.val() != "-1") {
				var route = {
					saddr: destinationAddress.val(),
					daddr: destination.val()
				};

				window.open('https://maps.google.com/?' + $.param(route), '_blank');
			}
			e.preventDefault();
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});